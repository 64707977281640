import { storyblokEditable } from '@storyblok/react'
import cn from '@utils/helpers/cn'

const SvgIcon = ({ blok }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: blok.content }}
      className={cn(
        `flex h-[${blok.height}px] w-[${blok.width}px]  max-h-[${blok.height}px] max-h-[${blok.height}px] min-w-[${blok.width}px] min-w-[${blok.width}px] [&>*]:max-h-[${blok.height}px] [&>*]:max-h-[${blok.height}px] [&>*]:min-w-[${blok.width}px] [&>*]:min-w-[${blok.width}px]`,
        blok.class,
      )}
      {...storyblokEditable(blok)}
    />
  )
}

export default SvgIcon
